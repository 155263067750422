import React, { useState, useEffect } from 'react';
import '../css-styles/Footer.css';

const Footer = () => {
    const [showFooter, setShowFooter] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const threshold = 100;
            const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - threshold;
            setShowFooter(isBottom);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!showFooter) return null;

    return (
        <footer className="footer">
            <p>Contact: <a href="mailto:seanpdudo@gmail.com">seanpdudo@gmail.com</a></p>
            <p>Last updated: July 2024</p>
        </footer>
    );
};

export default Footer;

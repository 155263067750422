// DetailCQSim.js
import React from 'react';
import '../../css-styles/DetailCQSim.css';  // Import your CSS file for this component
import Utilization from '../../media/CQSim/Utilization.gif'
import MaxWait from '../../media/CQSim/MaxWait.gif'
import JobWait from '../../media/CQSim/JobWait.gif'



// Define the functional component
const DetailCQSim = () => {
    return (
        <div className='detail-CQSim-container'>
            <div className='CQSim-image-banner'>
                <img src={MaxWait} alt="Job visual" />
                <img src={Utilization} alt="Home screen for website" />
                <img src={JobWait} alt="Search bar visual" />
            </div>
            <div className='text-container'>
                <div className="title">
                    <h1><u>CQSim GUI</u></h1>
                </div>
                <div className='text-section'>
                    <div className='text'>
                        <p>CQSim is a discrete event driven scheduling simulator.
                            Given a workload trace, it can simulate the scheduling behaviors of a HPC and output the data behind the simulation.
                            This is a very useful tool as you can test different scheduling procedures without having to fight/pay for time on an actual HPC.
                        </p>
                        <p>
                            To use CQSim, one must use the terminal line. This means consulting the manuals to figure out how to input the parameters you want to let it run.
                            There are a lot (43) of parameters and the output as the simulation runs moves to quick for any readability.
                        </p>
                        <p> The CQSim GUI is an interactive graphical user interface for CQSim, a simulation program.
                            This GUI provides users with an alternative way to interact with CQSim instead of entering parameters through the terminal.
                            Additionally, it offers the option to view real-time graph analysis while the simulation is running.
                            The GUI allows users to select between graphing Utilization, Max Wait Time, or Average Wait Time of the simulation.
                        </p>
                    </div>
                    <div className='list'>
                        <ul className="detail-CQSim-list border-list">
                            <li className='detail-item'>Research Project for <strong>Big Data X REU</strong> Program</li>
                            <li className='detail-item'>Completed in August 2023</li>
                            <li className='detail-item'><strong>Tools used</strong>: Python, TKinter, Github</li>
                            <li className='detail-item'><strong>Github Page:</strong> <a href='https://github.com/SPEAR-UIC/CQSim/tree/CQSim-GUI'> CQSim GUI</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailCQSim;
import headshot from "../media/croppedProfile.jpg"
import banner from "../media/Pseudo-Sudo-Dudo.png"
import '../css-styles/Intro.css'
import Typewriter from "typewriter-effect";
// This is a comment?

export default function Intro() {
    return (
        <>
            <div className="total-container">
                <div className="intro-component-container">
                    <div className="picture-container">
                        <img src={headshot} alt="profile pic" className="headshot" />
                    </div>
                    <div className="name-intro-cont">
                        <div className="name-container">
                            <h1 id="large-font"><Typewriter
                                onInit={(typewriter) => {
                                    typewriter
                                        .typeString("Welcome to my website!")
                                        .pauseFor(1000)
                                        .deleteAll()
                                        .typeString("Sean Dudo")
                                        .pauseFor(2000)
                                        .typeString(" :)")
                                        .start();
                                }}
                            /></h1>
                        </div>
                        <div className="intro-text-cont">
                            <p className="intro-text"> Welcome to my website!
                                My name is Sean Dudo and I am a computer scientist who graduated from the University of Texas at Austin.
                                This website is a way for me to continue practicing all of the skills I learned in my classes as well as a way to display my accomplishments to those who are interested!
                                This is my first website that I have developed from start to finish so feel free to contact me with some bugs you find on the way :P
                            </p>
                            <p className="intro-text"> Feel free to click the banner image below to send an email to me for any reason!</p>
                        </div>
                        <div className="website-banner-image">
                            {/* makes the banner clickable */}
                            <a href="mailto:seanpdudo@gmail.com?subject=Website%20Contact&body=Hello%20Sean%2C">
                                <img src={banner} className="bannerImg" alt="banner of projects"></img>
                            </a>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )

}
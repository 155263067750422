// DetailLAII.js
import React from 'react';
import '../../css-styles/DetailLAII.css';  // Import your CSS file for this component
import Jobs from '../../media/LAII/Jobs Viz.png'
import Search from '../../media/LAII/Search.png'
import LAII from '../../media/LAIILogo.png'


// Define the functional component
const DetailLAII = () => {
    return (
        <div className='detail-laii-container'>
            <div className='laii-image-banner'>
                <img src={Jobs} alt="Job visual" />
                <img src={LAII} alt="Home screen for website" />
                <img src={Search} alt="Search bar visual" />
            </div>
            <div className='text-container'>
                <div className="title">
                    <h1><u>LA Integration Initiative</u></h1>
                </div>
                <div className='text-section'>
                    <div className='text'>
                        <p>Our project serves immigrants within Los Angeles. We will provide additional resources to help address job security, affordable housing, and immigration resources.This will help immigrants coming into LA by providing them the resources they need to integrate into their new lives seamlessly. </p>
                        <p>Over the span of the semester we split our team into two groups, front end and back end. I chose to participate in the front end where I had to teach myself react and created unit tests using jest.
                            With the help of one other front end teammate, we designed the user interface for our website that incorporated a navbar, pagination, a search function and many API calls to keep our website populated and updated with current infromation.
                        </p>
                        <p>We also had to create a visualization for the data on OUR website and another group's website. We had to use our APIs and theirs to make graphs and charts to represent all of the data that they gathered. </p>
                    </div>
                    <div className='list'>
                        <ul className="detail-list border-list">
                            <li className='detail-item'>School Project for my <strong>Software Engineering Class</strong></li>
                            <li className='detail-item'>Completed in December of 2023</li>
                            <li className='detail-item'><strong>Tools used</strong>: React, Bootstrap, JavaScript, HTML, Gitlab, Python, CI, Jest</li>
                            <li className='detail-item'><strong>Gitlab Page:</strong> <a href='https://gitlab.com/pmogan77/group-7-swe'> LA Integration Initiative</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailLAII;
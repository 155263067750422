// GridBox.js
import React from 'react';
import '../css-styles/GridBox.css';

const GridBox = ({ image, caption, selected, onClick }) => {
    return (
        <div className="grid-box" onClick={onClick}>
            <div className={`grid-item ${selected ? 'selected' : ''}`}>
                <img src={image} alt={caption} className="grid-image" />
                <p className="grid-caption">{caption}</p>
            </div>
        </div>
    );
};

export default GridBox;
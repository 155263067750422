import '../css-styles/Projects.css'
import GridBox from './GridBox';
import CQSim from "../media/CQSim Logo.jpg"
import GTG from "../media/GTGLogo.png"
import LAII from "../media/LAIILogo.png"
import BOIDS from "../media/BOIDSLogo.png"
import React, { useState } from 'react';
import DetailBoid from './Project Details Components/DetailBOID.js'
import DetailCQSim from './Project Details Components/DetailCQSim.js'
import DetailGTG from './Project Details Components/DetailGTG.js'
import DetailLAII from './Project Details Components/DetailLAII.js'
// This is a comment?

export default function Projects() {
    const [selectedProjectFirstRow, setSelectedProjectFirstRow] = useState(null);
    const [selectedProjectSecondRow, setSelectedProjectSecondRow] = useState(null);

    const items = [
        { image: LAII, caption: 'LA Integration Initiative', details: DetailLAII },
        { image: GTG, caption: 'Go Touch Grass', details: DetailGTG },
        { image: BOIDS, caption: 'BOIDS R Us', details: DetailBoid },
        { image: CQSim, caption: 'CQSim GUI', details: DetailCQSim }
    ];

    const renderDetailComponent = (index) => {
        const DetailComponent = items[index].details;
        return <DetailComponent />;
    };

    return (
        <div className='Projects-Container'>
            <div className='title-div'>
                <h1 className='projects-title'>Projects</h1>
            </div>

            <div className="grid-container">
                <div className="first-row grid-row">
                    {items.slice(0, 2).map((item, index) => (
                        <GridBox
                            key={index} // You might need a more unique key
                            image={item.image}
                            caption={item.caption}
                            details={item.details}
                            index={index} // Use index 0 and 1
                            selected={selectedProjectFirstRow === index}
                            onClick={() => setSelectedProjectFirstRow(selectedProjectFirstRow === index ? null : index)} // Index is correct for the first row
                        />
                    ))}
                </div>
                <div className={`collapse ${selectedProjectFirstRow !== null ? 'show' : ''}`}>
                    {selectedProjectFirstRow !== null && (
                        <div className="card card-body">
                            {renderDetailComponent(selectedProjectFirstRow)}
                        </div>
                    )}
                </div>
            </div>

            <div className="grid-container">
                <div className="second-row grid-row">
                    {items.slice(2, 4).map((item, index) => (
                        <GridBox
                            key={index + 2} // Ensure unique key for second row items
                            image={item.image}
                            caption={item.caption}
                            details={item.details}
                            index={index + 2} // Index will be 2 and 3
                            selected={selectedProjectSecondRow === index}
                            onClick={() => setSelectedProjectSecondRow(selectedProjectSecondRow === index ? null : index)} // Toggle index for the second row
                        />
                    ))}
                </div>
                <div className={`collapse ${selectedProjectSecondRow !== null ? 'show' : ''}`}>
                    {selectedProjectSecondRow !== null && (
                        <div className="card card-body">
                            {renderDetailComponent(selectedProjectSecondRow + 2)}
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
}
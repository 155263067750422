import React from 'react';
import '../css-styles/Resume.css';

const Resume = () => {
    return (
        <div className="resume">
            <header className="resume-header">
                <h1>Sean Dudo</h1>
                <p>Email: <a href="mailto:seanpdudo@gmail.com">seanpdudo@gmail.com</a> | GitHub: <a href="https://github.com/Pseudo-Sudo-Dudo" target="_blank" rel="noopener noreferrer">Pseudo-Sudo-Dudo</a> | GitLab: <a href="https://gitlab.com/Pseudo-Sudo-Dudo" target="_blank" rel="noopener noreferrer">Pseudo-Sudo-Dudo</a></p>
            </header>

            <section className="resume-section">
                <h2>Education</h2>
                <p><strong>The University of Texas at Austin</strong>, Austin, TX <em className='date'>May 2024</em></p>
                <p>Bachelor of Science, Computer Science</p>
                <p><strong>Relevant Coursework:</strong> Intro Machine Learning, Operating Systems, Programming for Performance, Algorithms, Object Oriented Programming, Software Engineering, iOS App Development, Energy Efficient Computing</p>
            </section>

            <section className="resume-section">
                <h2>Skills</h2>
                <p><strong>Fluent</strong> in C++, and Python</p>
                <p><strong>Experiened</strong> with C, C#, Java, JavaScript, TypeScript, React, Swift, WebGL, SQL, HTML</p>
                <p><strong>Tools & IDEs:</strong> Eclipse, VSCode, IntelliJ, XCode, LaTeX</p>
                <p><strong>Version Control:</strong> Git, GitHub, GitLab</p>
            </section>

            <section className="resume-section">
                <h2>Work Experience</h2>
                <div className="experience">
                    <p><strong>National Science Foundation Big Data X Research Intern</strong>, Chicago, IL <em className='date'>May - August 2023</em></p>
                    <p><em>HPC Scheduling Research Intern</em></p>
                    <ul>
                        <li>Selected from a competitive pool of candidates representing leading research institutions nationwide</li>
                        <li>Spearheaded the development of an intuitive Tkinter-based GUI, enabling real-time analysis of the simulator</li>
                        <li>Collaborated actively with mentors and research heads to gain feedback to implement into my design</li>
                        <li>Presented a my research and submitted a poster to SC23 Conference</li>
                    </ul>
                </div>
                <div className="experience">
                    <p><strong>Harry Ransom Center at University of Texas</strong>, Austin, TX <em className='date'>August 2021 - January 2024</em></p>
                    <p><em>Library Assistant</em></p>
                    <ul>
                        <li>Worked 20 hours per week throughout undergraduate education</li>
                        <li>Managed physical & digital stacks and databases of archival materials</li>
                        <li>Serviced patrons to help make their research stay a pleasant experience</li>
                    </ul>
                </div>
                <div className="experience">
                    <p><strong>College Station ISD</strong>, College Station, TX <em className='date'>May 2020 - August 2022</em></p>
                    <p><em>Summer IT Technician</em></p>
                    <ul>
                        <li>Imaged laptops and connected iPads to school networks for virtual students during COVID </li>
                        <li>Installed computer labs and re-cabled classrooms</li>
                        <li>Handled help desk tickets and troubleshot issues in classrooms for teachers</li>
                    </ul>
                </div>
            </section>

            <section className="resume-section project-section">
                <h2>Projects</h2>
                <p className='resume-project'><strong>LA-Integration-Initiative:</strong> Designed the user interface and implemented front-end development. Coordinated with back-end developers to utilize API calls for pagination on our website</p>
                <p className='resume-project'><strong>Go Touch Grass (GTG):</strong> Conceptualized and developed an iOS wellness application using SwiftUI to enhance productivity through scheduled breaks. Integrated a Pomodoro timer and utilized geolocation features to encourage users to take outdoor breaks during study sessions.</p>
                <p className='resume-project'><strong>BOIDS R US:</strong> Created a Boids simulation in Unity and C#, implementing a quad and octree structure for efficient spatial partitioning in both 2D and 3D environments respectively.</p>
                <p className='resume-project'><strong>CQSim:</strong> Developed a graphical user interface (GUI) for a terminal-based supercomputer scheduler simulation, enabling users to adjust parameters and providing real-time analysis during the simulation.</p>
            </section>

            <section className="resume-section">
                <h2>Leadership & Community Involvement</h2>
                <div className="experience">
                    <p><strong>Head Chi Omega Kitchen Staff</strong> <em className='date'>August 2022 - May 2024</em></p>
                    <ul>
                        <li>Taught new members how to serve at the house and organizes shift covering</li>
                    </ul>
                </div>
                <div className="experience">
                    <p><strong>UT Dungeons and Dragons Club</strong> - Dungeon Master / Member <em className='date'>August 2021 - May 2022</em></p>
                    <ul>
                        <li>Organized and ran weekly sessions for a group of people to play D&D</li>
                    </ul>
                </div>
                <div className="experience">
                    <p><strong>Electronic Game Development Society (EGaDS)</strong> - Member <em className='date'>August 2021 - May 2024</em></p>
                    <ul>
                        <li>Attended workshops to improve programming skills in video games</li>
                    </ul>
                </div>
            </section>
        </div>
    );
};

export default Resume;

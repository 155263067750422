// DetailBOID.js
import React from 'react';
import '../../css-styles/DetailBOID.css';  // Import your CSS file for this component
import Quaduad from '../../media/BOIDs/quadtree.gif'
import Octree from '../../media/BOIDs/octree.gif'
import Boids from '../../media/BOIDs/boids.gif'



// Define the functional component
const DetailBOID = () => {
    return (
        <div className='detail-BOID-container'>
            <div className='BOID-image-banner'>
                <img src={Quaduad} alt="Job visual" />
                <img src={Boids} alt="Home screen for website" />
                <img src={Octree} alt="Search bar visual" />
            </div>
            <div className='text-container'>
                <div className="title">
                    <h1><u>BOIDS R US</u></h1>
                </div>
                <div className='text-section'>
                    <div className='text'>
                        <p>Our project is centered around simulating boid/flocking behavior using spatial data structures.
                            Boid/flocking behavior simulates the characteristics of a school of fish or a flock of birds as they group together in movement.
                            This can be simulated by defining a set of simple independent rules in order to achieve emergent behavior.
                            In our case these rules include: collision avoidance, velocity matching, and flock centering.</p>
                        <p>
                            In order to increase the performance of our simulation, we decided to use a spatial data structure.
                            Each entity in a boid must know about its neighbors in order to determine what forces to apply to itself, however, iterating through all entities per entity is a very costly process.
                            Spatial data structures allow each entity to only care about its immediate neighbors. In our case we chose to use a Quad-Tree for our 2D implementation (Far Left GIF) and an Oct-Tree for our 3D implementation (Far Right GIF).
                        </p>

                        <p> In our 2D implementation, we also coded 'predator' playable object. The red circle can be moved by the user and the boids
                            will change their acceleration depending on if it is near. However, if the boid is going too fast it may still run into the predator.
                        </p>
                    </div>
                    <div className='list'>
                        <ul className="detail-BOID-list border-list">
                            <li className='detail-item'>School Project for my <strong>Computer Graphics</strong></li>
                            <li className='detail-item'>Completed in May 2023</li>
                            <li className='detail-item'><strong>Tools used</strong>: Unity, C++, Quadtree, Octree, Github</li>
                            <li className='detail-item'><strong>Github Page:</strong> <a href='https://github.com/digocorbellini/boids-r-us?tab=readme-ov-file'> BOIDS R US</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailBOID;
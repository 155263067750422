// DetailGTG.js
import React from 'react';
import '../../css-styles/DetailGTG.css';  // Import your CSS file for this component
import Login from '../../media/GTG/Login.png'
import Map from '../../media/GTG/MapScreen.png'
import TimerDone from '../../media/GTG/TimerDone.png'
import Timer from '../../media/GTG/Timer.png'
import Profile from '../../media/GTG/ProfileSetup.png'
import Stats from '../../media/GTG/Stats.png'




// Define the functional component
const DetailGTG = () => {
    return (
        <div className='detail-GTG-container'>
            <div className='gtg-image-banner'>
                <img className="phone-ss" src={Login} alt="Home screen for website" />
                <img className="phone-ss" src={Profile} alt="Search bar visual" />
                <img className="phone-ss" src={Timer} alt="Home screen for website" />
                <img className="phone-ss" src={TimerDone} alt="Job visual" />
                <img className="phone-ss" src={Map} alt="Search bar visual" />
                <img className="phone-ss" src={Stats} alt="Home screen for website" />
            </div>
            <div className='text-container'>
                <div className="title">
                    <h1><u>Go Touch Grass</u></h1>
                </div>
                <div className='text-section'>
                    <div className='text'>
                        <p>This app was designed to promote wellness to enhance productivity. The app tracks the user's location and at the end of the timer, it suggests nearby points of interest. A user can customize the type of suggestions that they will receive.
                            If the user leaves the app, the timer resets and they must start over. This is to encourage phone free focus time while using the app.
                        </p>
                        <p>
                            With this app, I learned a great number of new development tools as iOS development was completely new for me. I learned XCode and Swift for our app's design.
                            My project group also had to learn about Firebase as our teacher required us to have a sign in system with registration. My main focus was developing the in app notifications
                            and the timer screen.
                        </p>
                    </div>
                    <div className='list'>
                        <ul className="detail-gtg-list border-list">
                            <li className='detail-item'>School Project for my <strong>iOS Development Class</strong></li>
                            <li className='detail-item'>Completed in May 2024</li>
                            <li className='detail-item'><strong>Tools used</strong>: Swift, XCode, Github, Firebase</li>
                            <li className='detail-item'><strong>Github Page:</strong> <a href='https://github.com/pokator/GoTouchGrass'> Go Touch Grass</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailGTG;
// src/App.js
import React from 'react';
import './App.css';
import Intro from './components/Intro';
import 'bootstrap/dist/css/bootstrap.min.css';
import Projects from './components/Projects';
import Resume from './components/Resume';
import Footer from './components/Footer';
// import OldProjects from './components/OldProjects';


function App() {
  return (
    <div className="App">
      <div className='intro-container'>
        <Intro />
      </div>
      <div className="projects-container">
        <Projects />
      </div>
      <div className='resume-container'>
        <div><h1 className='resume-title'>Resume</h1></div>
        <Resume />
      </div>
      <div className='footer-container'>
        <Footer />
      </div>
    </div>
  );
}

export default App;
